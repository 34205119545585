import React from 'react';
import { Status } from '@brainstud/ui/Static/Status';
import { useTranslator } from 'Providers/Translator';

type Props = {
  /** The current status of the answer, e.g. TURNED_IN, ACCEPTED or REJECTED */
  value: string;
};

/**
 * Shows the status of the answer
 */
export const AnswerStatus = ({ value }: Props) => {
  const [t] = useTranslator();
  const color =
    {
      accepted: 'green',
      rejected: 'red',
    }[value.toLowerCase()] || 'yellow';

  return (
    <Status
      scheme={color as 'green' | 'red' | 'yellow'}
    >{` ${t(`states.${value.toLowerCase()}`)}`}</Status>
  );
};
