import React, { useState } from 'react';
import { useAnswers } from '@brainstud/academy-api/Hooks/useAnswers';
import { useEnrollments } from '@brainstud/academy-api/Hooks/useEnrollments';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { Answer } from '@brainstud/academy-api/Types/Resources/Answer';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Table, TdCheckbox, Tr } from '@brainstud/ui/Static/Table';
import { useDataSelection } from '@brainstud/universal-components/Providers/DataProvider/useDataSelection';
import { Add } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { ContentCard } from 'Components/Cards/ContentCard';
import { Link } from 'Components/Link';
import { Loading } from 'Components/Loading';
import { Wrap } from 'Components/Wrap';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { SelectionMenu } from '../../../Components/SelectionMenu';
import { ExpositionModalForm } from '../../../Modals';
import { MetaActionsBar } from './MetaActionsBar/MetaActionsBar';
import { AnswerRow } from './AnswerRow';
import styles from './AnswersView.module.css';

const cx = classNames.bind(styles);

type Props = {
  filter?: (answer: Answer) => boolean;
  selectable?: boolean;
};

export const AnswersView = ({ filter = () => true, selectable }: Props) => {
  const [t] = useTranslator();
  const { selection, handleToggleSelectAll, handleToggleSelect } =
    useDataSelection();
  const { openModal } = useModals();
  const [me] = useMe();
  const account = me?.account?.();
  const accountId = account?.id;

  const [isGridView, setIsGridView] = useState(true);

  const [courseFilters, setCourseFilters] = useState<string[]>([]);
  const [sortOrder, setSortOrder] = useState<'created_at' | '-created_at'>(
    'created_at'
  );
  const [search, setSearch] = useState<string>();

  const [{ data: enrollments }] = useEnrollments(
    {
      filter: {
        account: accountId,
      },
      include: ['course'],
    },
    { enabled: !!accountId }
  );

  const [{ data: answers }, { isLoading }] = useAnswers({
    filter: {
      account: accountId,
      course: courseFilters.length > 0 ? courseFilters.join() : undefined,
      status: 'TURNED_IN,ACCEPTED,REJECTED',
      ...(search ? { 'learning_object.title': search } : {}),
    },
    include: ['course', 'learning_object', 'learning_object_variety', 'course'],
    sort: [sortOrder],
  });

  const visibleAnswers = answers.filter(
    (item) => (!filter || filter(item)) && item.learningObject?.()
  );

  return (
    <div className={styles.base}>
      <MetaActionsBar
        enrollments={enrollments}
        onToggleViewType={setIsGridView}
        onSort={setSortOrder}
        onSearch={setSearch}
        onFilterChange={setCourseFilters}
      />
      {isLoading ? (
        <Loading />
      ) : isGridView ? (
        <div className={cx(styles['card-wrapper'])}>
          {visibleAnswers.map((answer) => (
            <Wrap
              key={answer.id}
              if={!selectable}
              // eslint-disable-next-line react/no-unstable-nested-components
              with={({ children }) => (
                <Link
                  href={`/courses/${answer.course?.().id}/detail/${answer.learningObject?.().id}`}
                  className={cx(styles['detail-link'])}
                >
                  {children}
                </Link>
              )}
            >
              <div className={styles['content-wrap']}>
                <ContentCard
                  key={answer.id}
                  object={answer.learningObject?.()}
                  answerStatus={answer.status}
                  onCheck={() =>
                    handleToggleSelect([answer.learningObject!().id])
                  }
                  checked={selection.includes(answer.learningObject!().id)}
                />
              </div>
            </Wrap>
          ))}
        </div>
      ) : (
        <Panel>
          <Table>
            <Table.THead>
              <Tr>
                <TdCheckbox
                  id={visibleAnswers.map((item) => item.learningObject!().id)}
                />
                <th>{t('views.portfolio.answers.table.assignment')}</th>
                <th>{t('views.portfolio.answers.table.xp')}</th>
                <th>{t('views.portfolio.answers.table.status')}</th>
                <th>{t('views.portfolio.answers.table.due_date')}</th>
              </Tr>
            </Table.THead>
            <Table.TBody>
              {visibleAnswers.map((answer) => (
                <AnswerRow
                  key={answer.id}
                  learningObject={answer.learningObject?.()}
                  points={answer.learningObjectVariety?.()?.points}
                  status={answer.status}
                  date={answer.updatedAt}
                  href={`/courses/${answer.course?.().id}/detail/${answer.learningObject?.().id}`}
                />
              ))}
            </Table.TBody>
          </Table>
        </Panel>
      )}

      <SelectionMenu open={selection.length > 0}>
        <Button
          outline
          type="button"
          onClick={() =>
            openModal(ExpositionModalForm, {
              learningObjectIds: selection,
            })
          }
        >
          <Add fontSize="large" />
          <span>{t('views.portfolio.answers.create_exposition')}</span>
        </Button>
        <Button outline type="button" onClick={() => handleToggleSelectAll()}>
          <span>{t('cancel')}</span>
        </Button>
      </SelectionMenu>
    </div>
  );
};
