import React, { CSSProperties } from 'react';
import { Close } from '@mui/icons-material';
import classNames from 'classnames/bind';
import styles from './Chip.module.css';

const cx = classNames.bind(styles);

type Props = {
  label: string;
  onClose?: () => void;
  style?: CSSProperties;
  className?: string;
};

/**
 * Chip.
 *
 * The Chip is a compact element that can show the result of an input, attribute or action. for example show what filters are applied.
 * This component also has a controlled close functionality
 */
export const Chip = ({ label, onClose, className, style }: Props) => (
  <div className={cx(styles.base, className)} style={style}>
    <strong>{label}</strong>
    <button type="button" className={cx(styles.close)} onClick={onClose}>
      <Close />
    </button>
  </div>
);
